import http from "./http";

export function getAllUserRoles() {
  return http.get("/userrole/all");
}

export function getUserRoleById(id) {
  return http.get(`/userrole/${id}`);
}

export function addUserRole(userId, data) {
  return http.post(`/userrole/add/${userId}`, data);
}

export function editUserRole(id, userId, data) {
  return http.patch(`/userrole/edit/${id}/${userId}`, data);
}

export function deleteUserRole(id, userId) {
  return http.delete(`/userrole/delete/${id}/${userId}`);
}

export function logUserVisit(userId, route) {
  return http.post(`/userrole/log-visit/${userId}`, { route });
}

export function visitPerDay() {
  return http.get("/userrole/visit-count-per-day");
}

export function totalPageVisitCount() {
  return http.get("/userrole/get-total-route-visit-count");
}
