import jwtDecode from "jwt-decode";
import http from "./http";
import { toast } from "react-toastify";

export function login(form) {
  // const config = {
  //   headers: {
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //   },
  // };
  return http.post("/user/login", form);
}

export function register(data) {
  const emailType = localStorage.getItem("iReferSecureEmail");
  return http.post("/user/register", data, { params: { emailType } });
}

export function logout() {
  localStorage.removeItem("irefer");
  localStorage.removeItem("authToken");
  sessionStorage.removeItem("visitedRoute_/");
  sessionStorage.removeItem("visitedRoute_/irefer/");
  sessionStorage.removeItem("visitedRoute_/irefer/dashboard");
  sessionStorage.removeItem("visitedRoute_/irefer/incentives");
  sessionStorage.removeItem("visitedRoute_/irefer/profile");
  sessionStorage.removeItem("visitedRoute_/irefer/referrals");
  sessionStorage.removeItem("visitedRoute_/irefer/iApply");
  sessionStorage.removeItem("visitedRoute_/irefer/generalreferral");
  sessionStorage.removeItem("visitedRoute_/irefer/viewjobs");
  sessionStorage.removeItem("visitedRoute_/irefer/merch");
}

export function getCurrentUser() {
  const token = localStorage.getItem("irefer");
  const authToken = localStorage.getItem("authToken");

  if (!authToken) {
    logout();
    return null;
  }

  if (token) {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp;
    const currentTime = Date.now() / 1000;
    if (expirationTime < currentTime) {
      toast.info("Session has expired. Please login again.");
      localStorage.removeItem("irefer");
      localStorage.removeItem("authToken");
      return null;
    } else {
      return decodedToken.user;
    }
  }
  return null;
}

export async function getRandomString() {
  try {
    const response = await http.get("/generateRandomString");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function handleUnauthorizedError() {
  const user = getCurrentUser();
  if (user) {
    toast.info("Session has expired. Logging out.");
    localStorage.removeItem("irefer");
    localStorage.removeItem("authToken");
  }
}
